<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-profile></sidebar-profile>
          <div class="page__content">
            <Card title="Endereço" id="endereco">
              <div class="row">
                <RInput
                  v-model="form.address.cep"
                  label="CEP"
                  mask="#####-###"
                  class="col-md-4"
                />
                <RInput
                  v-model="form.address.street"
                  label="Rua"
                  class="col-md-8"
                />
                <RInput
                  v-model="form.address.number"
                  label="Número"
                  class="col-md-4"
                />
                <RInput
                  v-model="form.complement"
                  label="Complemento"
                  class="col-md-8"
                />
                <RInput
                  v-model="form.address.district"
                  label="Bairro"
                  class="col-md-4"
                />
                <Select
                  label="Estado"
                  v-model="form.address.province_id"
                  :items.sync="provinces"
                  labelName="name"
                  class="col-12 col-md-4"
                />
                <Select
                  label="Cidade"
                  v-model="form.address.city_id"
                  :items.sync="cities"
                  class="col-12 col-md-4"
                  labelName="name"
                />
                <div class="col-12">
                  <button
                    @click="saveAddress"
                    type="button"
                    class="button_outline"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </Card>

            <Card title="Dados de acesso" id="dados-de-acesso">
              <div class="row">
                <RInput
                  type="email"
                  :readonly="true"
                  v-model="form.userdata.email"
                  label="E-mail de cadastro"
                />
                <RInput
                  type="password"
                  v-model="form.userdata.password"
                  label="Senha"
                  placeholder="**********"
                />
                <div class="col-12">
                  <button
                    @click="saveData"
                    type="button"
                    class="button_outline"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </Card>

            <Card title="Documentos" id="documentos">
              <div class="row">
                <div class="col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Documento</th>
                        <th>Formato</th>
                        <th>Status</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="userdoc in form.userdocs" :key="userdoc.id">
                        <td>
                          <span>{{ userdoc.document }}</span>
                        </td>
                        <td><span>DOC</span></td>
                        <td>
                          <span> {{ userdoc.approved_status }}</span>
                        </td>
                        <td class="table-actions">
                          <a :href="`${userdoc.doc_url}`" target="_blank">
                            <i class="far fa-eye view_icon"></i>
                          </a>
                          <i
                            class="fas fa-edit edit_icon"
                            @click="openEdit(userdoc)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <modal :modal="editingDoc" @changeModal="val => (editingDoc = val)">
      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">
            {{ docForm.id ? 'Editar' : 'Adicionar novo' }} documento
          </h2>
        </div>
        <div class="row">
          <RInput
            v-model="docForm.document"
            label="Tipo de Documento"
            placeholder="Ex.: CNPJ"
            type="text"
            class="col-12"
          />
          <RInput
            v-model="docForm.file"
            label="Documento"
            type="file"
            class="col-12"
          />
        </div>
        <div class="footer_modal">
          <button
            type="button"
            @click.prevent="saveDocument"
            class="button_outline"
            :disabled="savingDocument"
          >
            {{ savingDocument ? 'Salvando...' : 'Salvar' }}
          </button>
        </div>
      </div>
    </modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import swal from 'sweetalert'

const defaultForm = {
  address: {
    zip_code: '',
    cep: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    province_id: '',
    city_id: ''
  },
  complement: '',
  userdata: {
    company_name: '',
    id: '',
    cpf: ''
  },
  userdocs: [],
  email: '',
  password: ''
}

const defaultDocForm = {
  approved: 0,
  document: '',
  file: null,
  id: '',
  user_id: ''
}

export default {
  data: () => ({
    form: { ...defaultForm },
    docForm: { ...defaultDocForm },
    editingDoc: false,
    savingDocument: false,
    user: {}
  }),
  methods: {
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('city', { fetchCities: 'fetch', getViaCEP: 'getViaCEP' }),
    async saveAddress () {
      this.loading = true
      try {
        const data = this.form.address
        const url = 'auth/address/update'
        await axios.post(url, data)

        swal({
          title: 'Sucesso!',
          text: 'Endereço atualizado com sucesso.',
          icon: 'success',
          timer: 4000
        }).then(() => {})
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    async saveData () {
      this.loading = true
      try {
        const data = this.form.userdata
        const url = 'auth/user/update'
        await axios.post(url, data)

        swal({
          title: 'Sucesso!',
          text: 'Dados pessoais atualizados com sucesso.',
          icon: 'success',
          timer: 4000
        }).then(() => {})
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    async getData () {
      const url = `auth/user/user?id=${this.user.id}`
      const { data } = await this.$http.post(url)
      this.form.address = data.data.address[0]
      this.form.complement = data.data.address[0].complement
      this.form.userdata = data.data.dados[0]
      this.form.userdocs = data.data.docs
      this.loading = false
      // TODO: chamar api para buscar os dados do user e salvar na variavel this.form
    },
    openEdit (userDoc) {
      this.docForm = {
        ...defaultDocForm,
        id: userDoc.id,
        document: userDoc.document,
        user_id: userDoc.user_id
      }
      this.editingDoc = true
    },
    newDocument () {
      this.docForm = { ...defaultDocForm }
      this.editingDoc = true
    },
    async saveDocument () {
      this.savingDocument = true
      const url = 'user-docs'
      const data = new FormData()

      Object.keys(this.docForm).map(item => {
        if (this.docForm[item]) data.append(item, this.docForm[item])
      })

      data.append('user_id', this.user.id)

      await axios.post(url, data)
      await this.getData()
      this.editingDoc = false
      this.savingDocument = false
      this.docForm = { ...defaultDocForm }
    }
  },
  computed: {
    ...mapGetters('province', ['provinces']),
    ...mapGetters('city', ['cities'])
  },
  mounted () {
    const localUser = localStorage.getItem('user')
    const user = JSON.parse(localUser)
    this.user = user
    this.fetchProvinces()
    this.fetchCities()
    this.getData()
  },
  components: {
    sidebarProfile: () => import('@/components/sidebar-profile'),
    Card: () => import('@/components/card'),
    RInput: () => import('@/components/input'),
    Select: () => import('@/components/select'),
    Modal: () => import('@/components/modal')
  },
  watch: {
    'form.address.province_id' (val, old) {
      this.fetchCities({ province: val })
      if (old) this.form.city_id = undefined
    },
    async 'form.address.cep' (val) {
      if (this.isEditing) return
      if (!val || val.length < 8) return
      const data = await this.getViaCEP({ zipCode: val })
      this.form.address = Object.assign(this.form.address, data)
    }
  }
}
</script>

<style scoped>
button {
  margin-left: 0.5rem;
  padding: 1rem;
  font-weight: bold;
  min-width: 25%;
}
table thead th:nth-child(1) {
  width: 100%;
  height: auto;
}

.table-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view_icon,
.edit_icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 20px;
  cursor: pointer;
}

.view_icon {
  color: #44be6b;
}
.edit_icon {
  color: #00a5d7;
}
</style>
